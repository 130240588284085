import React, { createRef, RefObject, useEffect, useRef, useState } from 'react';
import ButtonGroup, { GroupButton } from '../common/ButtonGroup';
import { useTranslate } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslations, INITIAL_POWERS } from '../../constants';
import { actionCreators, ApplicationState } from '../../store';
import { ButtonType } from '../common/Button';
import { SELECTABLE_APPLICATIONS } from '../../constants';
import { CONVERSION_VALUE } from '../../constants/ConversionValue';
import { getCommunications, getFilteredLineList, filterDeletedLineItems, getRatedVoltages } from '../../services/FilterService';
import { CAROUSEL_RESPONSIVE } from '../../constants/CarouselResponsive';
import Card from './Card';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Jumbotron from '../common/Jumbotron';
import KeyVisual from '../common/KeyVisual';
import Section, { SectionColor } from '../common/Section';
import Select from '../common/Select';
import Carousel from '../common/Carousel';
import SelectorTagBar from './SelectorTagBar';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import DefaultBanner from '../../../assets/images/banners/lv-converters-default.jpg';
import MachiningBanner from '../../../assets/images/banners/lv-converters-machining.jpg';
import MovingBanner from '../../../assets/images/banners/lv-converters-moving.jpg';
import PositioningBanner from '../../../assets/images/banners/lv-converters-positioning.jpg';
import ProcessingBanner from '../../../assets/images/banners/lv-converters-processing.jpg';
import PumpingBanner from '../../../assets/images/banners/lv-converters-pumping.jpg';
import Icon, { IconType } from '../common/Icon';
import Loader from '../common/Loader';
import { LineData, LineValues } from '../../models';
import NumberInput from '../common/NumberInput';
import { ApplicationType } from '../../enums';

interface SelectorProps {
    powers: number[]
}

const Selector = ({ powers }: SelectorProps) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = getTranslations();
    const filter = useSelector((state: ApplicationState) => state.selector.filter);
    const dtkData = useSelector((state: ApplicationState) => state.selector.dtkData);
    const loading = useSelector((state: ApplicationState) => state.selector.loading);
    const lineData = useSelector((state: ApplicationState) => state.selector.lineData);
    const hiddenIds = useSelector((state: ApplicationState) => state.selector.hiddenIds);
    const callerData = useSelector((state: ApplicationState) => state.selector.callerData);
    const lineValues = useSelector((state: ApplicationState) => state.selector.lineValues);
    const moreVoltageOpen = useSelector((state: ApplicationState) => state.selector.moreVoltageOpen);
    const Range = createSliderWithTooltip(Slider.Range);
    const dtkKeepAliveIframe = useRef<HTMLIFrameElement>(null);
    const [cardRefs, setCardRefs] = useState<RefObject<HTMLDivElement>[]>([]);
    const [closedVoltageHeight, setClosedVoltageHeight] = useState<number>();
    const [maxPropertyHeights, setMaxPropertyHeights] = useState<number[]>([]);
    const [filteredLineData, setFilteredLineData] = useState<LineData[]>([]);
    const [filteredLineValues, setFilteredLineValues] = useState<LineValues>(lineValues);
    const filteredHiddenLineData = filterDeletedLineItems(filteredLineData, hiddenIds);

    useEffect(() => {
        let interval: NodeJS.Timer;

        if (dtkData) {
            interval = setInterval(() => {
                if (dtkKeepAliveIframe.current) {
                    dtkKeepAliveIframe.current.src = dtkKeepAliveIframe.current.src;
                }
            }, 60000);
        }

        return () => clearInterval(interval);
    }, [dtkData]);

    useEffect(() => {
        setCardRefs(refs => new Array(filteredLineData.length).fill({}).map((_, i) => refs[i] || createRef<HTMLDivElement>()));
    }, [filteredLineData]);

    useEffect(() => {
        setClosedVoltageHeight(maxPropertyHeights[0]);
        setPropertyHeights();
    }, [cardRefs]);

    useEffect(() => {
        setPropertyHeights();
    }, [moreVoltageOpen]);

    useEffect(() => {
        filterLineDataAndValues();
    }, [lineData, filter.application, filter.category, filter.communication, filter.isKwSelected, filter.phases, filter.powerMin, filter.powerMax, filter.voltageRange]);

    const getKeyVisual = () => {
        switch (filter.application?.toLowerCase()) {
            case 'machining':
                return MachiningBanner;
            case 'moving':
                return MovingBanner;
            case 'positioning':
                return PositioningBanner;
            case 'processing':
                return ProcessingBanner;
            case 'pumping':
                return PumpingBanner;
            default:
                return DefaultBanner;
        }
    };

    const getSelectableApplicationValues = (getApplicationNames: boolean) => {
        return filteredLineValues.applications
            .map(selection => ({ selection, application: SELECTABLE_APPLICATIONS.find(y => ApplicationType[y.name] === selection) }))
            .filter(x => x.application)
            .map(x => getApplicationNames ? translate((translations.lvconverters.application as any)[x.selection]) : x.application ? x.application.image : '');
    };

    const getMarks = () => Object.assign({}, new Array(powers.length).fill(''));

    const setRatedVoltage = (ratedVoltage: string) => {
        const splittedVoltage = ratedVoltage.split(' ');
        
        dispatch(actionCreators.setFilter({ phases: splittedVoltage[0], voltageRange: `${splittedVoltage[1]} - ${splittedVoltage[3]}` }));
    };

    const setPropertyHeights = () => {
        const maxPropertyTempHeights: number[] = [];

        cardRefs && cardRefs.map((cardRef: RefObject<HTMLDivElement>) => {
            const cardRefArray = cardRef?.current && [].slice.call(cardRef.current.children);

            cardRefArray && cardRefArray.map((cardProperty: HTMLDivElement, i: number) => {
                if (i == 0 && moreVoltageOpen) {
                    const voltageHeight = [].slice.call(cardProperty.children).map((voltageChild: HTMLDivElement) => voltageChild.clientHeight).reduce((prev: number, curr: number) => prev + curr, 0);

                    if (voltageHeight > maxPropertyTempHeights[i] || maxPropertyTempHeights[i] == undefined)
                        maxPropertyTempHeights[i] = voltageHeight;
                }
                if (i < 4 && (cardProperty.scrollHeight > maxPropertyTempHeights[i] || maxPropertyTempHeights[i] == undefined)) {
                    maxPropertyTempHeights[i] = cardProperty.scrollHeight;
                }
                else if (i == 4) {
                    [].slice.call(cardProperty.children).forEach((children: HTMLDivElement, j: number) => {
                        const propertyIndex = i + j;

                        if (children.scrollHeight > maxPropertyTempHeights[propertyIndex] || maxPropertyTempHeights[propertyIndex] == undefined)
                            maxPropertyTempHeights[propertyIndex] = cardProperty.children[j].scrollHeight;
                    });
                }
            });
        });

        if (closedVoltageHeight == undefined) {
            setClosedVoltageHeight(maxPropertyTempHeights[0]);
        }
        else if (!moreVoltageOpen && closedVoltageHeight) {
            maxPropertyTempHeights[0] = closedVoltageHeight;
        }

        setMaxPropertyHeights([...maxPropertyTempHeights]);
    };

    const getCurrentPowerIndex = (power: number) => {
        let index = powers.indexOf(power);

        if (index === -1) {
            for (let i = 0; i < powers.length; i++) {
                if (powers[i] <= power) {
                    index = i;
                }
            }
        }

        return index;
    };

    const calculatePowerUnit = (power: number) => {
        let currentPower = power ? power : 0;

        currentPower = !filter.isKwSelected ? currentPower / CONVERSION_VALUE : currentPower * CONVERSION_VALUE;
        const closestPower = powers.reduce((prev, current) => Math.abs(current - currentPower) < Math.abs(prev - currentPower) ? current : prev);

        return powers[getCurrentPowerIndex(closestPower)];
    };

    const handleRatedPowerChange = () => {
        dispatch(actionCreators.setFilter({
            isKwSelected: !filter.isKwSelected,
            powerMin: calculatePowerUnit(filter?.powerMin ?? INITIAL_POWERS.powerMin),
            powerMax: calculatePowerUnit(filter?.powerMax ?? INITIAL_POWERS.powerMax)
        }));
    };

    const handlePowerRangeChange = (power: number[]) => dispatch(actionCreators.setFilter({ powerMin: powers[power[0]], powerMax: powers[power[1]] }));

    const handlePowerInputChange = (power: number, inputName: string) => {
        inputName === 'min-power-input' ?
            dispatch(actionCreators.setFilter({ powerMin: power })) :
            dispatch(actionCreators.setFilter({ powerMax: power }));
    };

    const handlePowerInputBlur = (isMinValue: boolean) => {
        const minPower: number = filter.isKwSelected ? lineValues.minAndMaxPowers.minPowerKw : lineValues.minAndMaxPowers.minPowerHp;
        const maxPower: number = filter.isKwSelected ? lineValues.minAndMaxPowers.maxPowerKw : lineValues.minAndMaxPowers.maxPowerHp;
        const power = isMinValue ? filter.powerMin : filter.powerMax;

        if (power != undefined) {
            if (power > maxPower) {
                dispatch(actionCreators.setFilter({ powerMax: power }));
            }
            if (power < minPower) {
                dispatch(actionCreators.setFilter({ powerMin: minPower }));
            }
        }

        if (filter.powerMin && filter.powerMax) {
            if (isMinValue && filter.powerMin > filter.powerMax) {
                dispatch(actionCreators.setFilter({ powerMax: filter.powerMin }));
            }
            else if (!isMinValue && filter.powerMax < filter.powerMin) {
                dispatch(actionCreators.setFilter({ powerMin: filter.powerMax }));
            }
        }
    };

    const filterLineDataAndValues = () => {
        setFilteredLineValues({
            ...lineValues,
            communications: getCommunications(lineData, filter, callerData.CALLER),
            ratedVoltages: getRatedVoltages(lineData, filter, callerData.CALLER)
        });

        setFilteredLineData(getFilteredLineList(lineData, filter, callerData.CALLER));
    };

    const viewButtons: GroupButton[] = [
        { children: <div>kW</div>, disabled: filter.isKwSelected, onClick: handleRatedPowerChange },
        { children: <div>hp</div>, disabled: !filter.isKwSelected, onClick: handleRatedPowerChange }
    ];

    return (
        <div className='selector'>
            <KeyVisual height={360} url={getKeyVisual()}>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <div className='select-application'>
                            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                                <Select
                                    label={translate(translations.lvconverters.selectApplication)}
                                    values={getSelectableApplicationValues(true)}
                                    value={filter.application}
                                    mapToString={x => x?.toString() ?? ''}
                                    onSelect={x => dispatch(actionCreators.setFilter({ application: x && x.includes('Pumping') ? x?.split('/')[0] : x }))}
                                    valueImages={getSelectableApplicationValues(false)}
                                />
                            </Flex>
                        </div>
                        <Jumbotron>
                            <div className='primary-filters'>
                                <div className='title'>{translate(translations.lvconverters.selection.title)}</div>
                                <div className='rated-power'>
                                    <div className='rated-power-title'>{translate(translations.lvconverters.selection.ratedPower)}</div>
                                    <ButtonGroup type={ButtonType.Secondary} buttons={viewButtons} />
                                </div>
                                <div className='power-slider'>
                                    <Range
                                        min={getCurrentPowerIndex(filter.isKwSelected ? lineValues.minAndMaxPowers.minPowerHp : lineValues.minAndMaxPowers.minPowerHp)}
                                        max={getCurrentPowerIndex(filter.isKwSelected ? lineValues.minAndMaxPowers.maxPowerKw : lineValues.minAndMaxPowers.maxPowerHp)}
                                        marks={getMarks()}
                                        step={null}
                                        dots={false}
                                        dotStyle={{ display: 'none' }}
                                        pushable={true}
                                        tipFormatter={(index: number) => {
                                            return `${powers[index]} ${filter.isKwSelected ? 'kW' : 'hp'}`;
                                        }}
                                        defaultValue={[getCurrentPowerIndex(filter?.powerMin ?? INITIAL_POWERS.powerMin), getCurrentPowerIndex(filter?.powerMax ?? INITIAL_POWERS.powerMax)]}
                                        onAfterChange={handlePowerRangeChange}
                                    />
                                    <div className='power-input-container'>
                                        <NumberInput
                                            label=''
                                            name='min-power-input'
                                            value={`${filter?.powerMin}`}
                                            onChange={(power, name) => !isNaN(Number(power)) && power.length < 6 && handlePowerInputChange(Number(power), name)}
                                            onBlur={() => handlePowerInputBlur(true)}
                                            decimal
                                        />
                                        <div className='power-line'>
                                            <Icon type={IconType.Minus} />
                                        </div>
                                        <NumberInput
                                            label=''
                                            name='max-power-input'
                                            value={`${filter?.powerMax}`}
                                            onChange={(power, name) => !isNaN(Number(power)) && power.length < 6 && handlePowerInputChange(Number(power), name)}
                                            onBlur={() => handlePowerInputBlur(false)}
                                            decimal
                                        />
                                        <div className='line-selection-power-unit'>
                                            <p>{filter.isKwSelected ? 'kW' : 'hp'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='dropdown-filters'>
                                    <Select
                                        label={translate(translations.lvconverters.selection.ratedVoltage)}
                                        value={filter.phases && filter.voltageRange ? `${filter.phases} ${filter.voltageRange} V` : ''}
                                        values={filteredLineValues.ratedVoltages.map(x => `${x.phases} ${x.voltageRange} V`)}
                                        mapToString={x => x.toString()}
                                        onSelect={x => setRatedVoltage(x)}
                                    />
                                    <Select
                                        label={translate(translations.lvconverters.selection.communication)}
                                        value={filter.communication}
                                        values={filteredLineValues.communications}
                                        mapToString={x => x?.toString() ?? ''}
                                        onSelect={x => dispatch(actionCreators.setFilter({ communication: x }))}
                                    />
                                </div>
                            </div>
                        </Jumbotron>
                    </ContentContainer>
                </Flex>
            </KeyVisual>
            <Section color={SectionColor.LightSand} style={{ paddingTop: 15 }}>
                <ContentContainer>
                    <SelectorTagBar backgroundColor={SectionColor.LightSand} />
                    <div className='results'>
                        <Loader loading={loading}>
                            {filteredHiddenLineData.length ?
                                <Carousel slidesToShow={4} responsive={CAROUSEL_RESPONSIVE}>
                                    {filteredHiddenLineData.map((x, i) =>
                                        <div key={x.id}>
                                            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                                                <Card
                                                    key={x.id}
                                                    lineData={x}
                                                    cardRef={cardRefs[i]}
                                                    propertyHeights={maxPropertyHeights}
                                                />
                                            </Flex>
                                        </div>
                                    )}
                                </Carousel> :
                                <div className='results-placeholder'>
                                    {!loading && translate(translations.lvconverters.noResults)}
                                </div>
                            }
                        </Loader>
                    </div>
                </ContentContainer>
            </Section>
            {dtkData &&
                <iframe ref={dtkKeepAliveIframe} className='dtkKeepAliveIframe' src={dtkData.KEEPALIVEURL} />
            }
        </div>
    );
};

export default Selector;
