import * as React from 'react';
import { LineData, Filter, RatedVoltage } from '../models';
import { CATEGORIES_WITH_KMAT_ORDERS } from '../constants';
import _, { result } from 'lodash';

// Gets the line results by the given filter ordered by the specified Kmat
export const getFilteredLineList = (lineData: LineData[], filter: Filter, callerName: string): LineData[] => {   
    const results: LineData[] = lineData.filter(item =>
        (!filter.application || item.application.includes(filter.application)) &&
        (!filter.phases || filter.phases === item.phases) &&
        filterForVoltageRange(filter, item) &&
        (filterForCommunication(filter, item)) &&
        (!filter.category || filter.category === item.category) &&
        filterForCaller(callerName, item, lineData) &&
        filterForPower(filter, item)
    );

    return _.uniqBy(orderLineItems(results), (item: LineData) => [item.kmat, item.category].join());
};

export const filterDeletedLineItems = (lineData: LineData[], hiddenIds: number[]) => lineData.filter(x => !hiddenIds.includes(x.id));

// Gets the unique sorted rated voltages depending on the given filter
export const getRatedVoltages = (lineData: LineData[], filter: Filter, callerName: string): RatedVoltage[] => {
    const powerResult: LineData[] = getFilteredLineDataByPower(filter, lineData);

    const results: LineData[] = lineData.filter(item =>
        (!filter.application || item.application.includes(filter.application)) &&
        (filterForCommunication(filter, item)) &&
        (powerResult.length === 0 || powerResult.indexOf(item) > -1) &&
        filterForCaller(callerName, item, lineData)
    );

    const sortedRatedVoltages = Array.from(new Set((results.length === 0 ? lineData : results)
        .map(a => `${a.phases} ${a.voltageRange}`).map((item: string) => item))).sort();

    return sortedRatedVoltages.map(ratedVoltage => {
        return { phases: ratedVoltage.slice(0, 3), voltageRange: ratedVoltage.slice(4, ratedVoltage.length) };
    });
};

// Gets the unique sorted communications depending on the given filter
export const getCommunications = (lineData: LineData[], filter: Filter, callerName: string): string[] => {
    const splittedCommunications: string[] = [];
    const powerResult: LineData[] = getFilteredLineDataByPower(filter, lineData);
    const results: LineData[] = lineData.filter(item =>
        (!filter.application || item.application.includes(filter.application)) &&
        (!filter.phases || filter.phases === item.phases) &&
        filterForVoltageRange(filter, item) &&
        (powerResult.length === 0 || powerResult.indexOf(item) > -1) &&
        filterForCaller(callerName, item, lineData)
    );
    
    (results.length === 0 ? lineData : results).forEach(item => {
        item.communication.split(',').forEach(b => {
            if (!splittedCommunications.find(a => a === b)) {
                splittedCommunications.push(b.toString().trim());
            }
        });
    });

    return orderCommunications(Array.from(new Set(splittedCommunications)));
};

export const getFilteredLineDataByPower = (filter: Filter, lineData: LineData[]) => lineData.filter(item => filterForPower(filter, item));

export const filterForVoltageRange = (filter: Filter, lineData: LineData) => {
    const isG220LineData = lineData.kmat === 'G220';
    const specialVoltageRange = '380 - 480';
    const additionalValidVoltageRange = '380 - 500';      

    return !filter.voltageRange
        || filter.voltageRange === lineData.voltageRange
        || (isG220LineData && filter.voltageRange === specialVoltageRange && lineData.voltageRange === additionalValidVoltageRange);
};

export const filterForPower = (filter: Filter, lineData: LineData) => {
    const powerMin = filter.powerMin! < 1 ? 1 : filter.powerMin;

    return (!filter.powerMax && !powerMin) ||
        (filter.isKwSelected
            ? lineData.powerMinKw <= powerMin! && lineData.powerMaxKw >= filter.powerMax!
            : lineData.powerMinHp <= powerMin! && lineData.powerMaxHp >= filter.powerMax!);
};

export const filterForCommunication = (filter: Filter, lineData: LineData) => {
    const isG220LineData = lineData.kmat === 'G220';
    const additionalValidCommunication = 'PROFINET (RT / IRT, MRP & S2 Redundancy), EtherNet / IP';
    const profinetEthernetIp = 'PROFINET / EtherNet / IP';

    return !filter.communication
        || lineData.communication.includes(filter.communication)
        || (isG220LineData && filter.communication === profinetEthernetIp && lineData.communication.includes(additionalValidCommunication));
};

export const filterForCaller = (callerName: string, currentLineData: LineData, lineData: LineData[]) => {
    const uniqueCallerNames = lineData.map(a => a.callerNames).reduce((a, b) => a.concat(b), []).filter((item, i, ar) => ar.indexOf(item) === i);

    const callerNotExists = uniqueCallerNames.find(a => a.toUpperCase() === callerName.toUpperCase()) === undefined;

    return (callerNotExists || currentLineData.callerNames.some(a => a.toUpperCase() === callerName.toUpperCase()));
};

export const orderCommunications = (communications: string[]) => {
    const order = ['PROFINET / EtherNet / IP', 'PROFIBUS DP', 'USS', 'Modbus TCP', 'Modbus RTU', 'CANopen', 'AS-i', 'BACNet / FLN1'];
    const orderedCommunications: string[] = [];

    order.forEach(orderItem => {
        const currentCommunication = communications.find(communication => communication === orderItem);
        if (currentCommunication) {
            orderedCommunications.push(currentCommunication);
        }
    });

    return orderedCommunications;
};

export const orderLineItems = (results: LineData[]) => {
    let orderedResults: LineData[] = [];

    CATEGORIES_WITH_KMAT_ORDERS.forEach(nameAndKmats => {
        orderedResults = orderedResults.concat(filterLineDataResults(nameAndKmats.categoryName, nameAndKmats.kmatOrder, results));
    });    

    return orderedResults;
};

const filterLineDataResults = (categoryName: string, kmatOrder: string[], lineDataResults: LineData[]) => {
    let filteredResults: LineData[] = [];

    kmatOrder.forEach(kmat => {
        filteredResults = filteredResults.concat(lineDataResults.filter(x => x.kmat === kmat && x.category.toLowerCase() === categoryName));
    });

    return filteredResults;
};
